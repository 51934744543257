import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function Header(props) {
  // const [theme, setTheme] = React.useState("light");
  // const location = useLocation();
  // if (location.pathname === "/photos") {
  //   return null;
  // }
  return (
    <header id="header">
      <div className="app-heading">
        <Link to={"/"}>
          <h2>Alex Kostich | Creative Marketing</h2>
        </Link>

        <div className="header-links">
          {/* <button
            onClick={(e) => {
              e.preventDefault();
              if (theme === "light") {
                document.querySelector("html").classList.add("night -mode");
                setTheme("dark");
              } else {
                document.querySelector("html").classList.remove("night-mode");
                setTheme("light");
              }
            }}
          >
            theme toggle
          </button> */}
          <NavLink to="/photos">Photography</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>
      </div>
    </header>
  );
}
