import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

// Views
import Home from "../Views/Home.js";
import About from "../Views/About/About.js";
import Contact from "../Views/Contact/Contact.js";
import { Detail } from "../Views/Detail.js";

// Component
// import Header from "../components/Header/Header";
// import Footer from "../components/Footer/Footer";

// Global Styles
import "../styles/styles.scss";
import Header from "../Components/Header/Header.js";
import Footer from "../Components/Footer/Footer.js";
import Photos from "../Views/Photos/Photos.js";

function AppRouter(props) {
  return (
    <Router>
      <div className="app">
        <Header />

        <div className="app-container">
          <div className="view-container">
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/detail/:id" element={<Detail />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/photos" element={<Photos />}></Route>
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default AppRouter;
