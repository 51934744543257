import React from "react";

import { Link } from "react-router-dom";

const About = (props) => {
  return (
    <div className="App">
      <div className="about-copy">
        <p>
          <img
            alt={"Alex Kostich"}
            src={"http://alexkostich.com/images/photography/1A.jpg"}
          />
          Experienced creative advertising executive with demonstrated success
          creating, guiding and executing major 360 motion picture campaigns
          (print, A/V & digital). Collaborative and passionate leader, dynamic
          team player, proven excellence in launching franchise tent-pole
          properties and niche films with strategically creative and fiscally
          responsible multi-media campaigns.
        </p>
        <p>
          Strategic thinker with a commitment to out-of-the-box marketing ideas.
          Persuasive and diplomatic negotiating and presentation skills matched
          with excellent written communication abilities. Dynamic project
          manager with strong creative sensibility and performance-driven
          business acumen. Ability to manage simultaneous deadline-driven
          projects within budget in a high-pressure environment.
        </p>
        <p>
          Career experience includes junior executive and executive roles at top
          Fortune 500 entertainment conglomerates (Warner Bros. Studios, Sony
          Pictures Studios), while working on such global franchises as BATMAN,
          SPIDERMAN, JAMES BOND, THE DA VINCI CODE, THE SMURFS, and 21 & 22 JUMP
          STREET.
        </p>
        <p>
          Currently Senior Vice President of Creative Marketing at Participant,
          a film production/social impact company specializing in award-winning
          impactful content. Creative marketing and awards campaign work for
          Oscar-winning films including GREEN BOOK, ROMA and AMERICAN FACTORY.
          Key point of daily contact with studio distribution partners (Sony,
          Netflix, Focus Features, Warner Bros. etc.) on multiple narrative and
          documentary features, ensuring successful 360 campaign delivery and
          execution.
        </p>
        <p>
          Extensive background in crafting sales reels and materials for film
          festivals and markets as well as consumer-facing campaign strategy.
          Re-branded the company with key internal team (visual brand identity
          work, tone & voice development, highlight reel).
        </p>
        <Link to={"/contact"}>Get in touch</Link>
      </div>
    </div>
  );
};

export default About;
