import React, { useEffect, useState } from "react";
import Gallery from "react-grid-gallery";
import data from "../../assets.json";

const Photos = (props) => {
  const [images, setImages] = useState([]);
  const IMAGES = data.photography;

  useEffect(() => {
    document
      .getElementsByClassName("view-container")[0]
      .classList.add("photos-view-container");
    // let x = document.getElementsByClassName("view-container");
    // let i;
    // for (i = 0; i < x.length; i++) {
    //   x[i].className += " photos-view-container"; // WITH space added
    // }

    // document
    //   .getElementsByClassName("view-container")
    //   .classList.add("photos-view-container");
    if (IMAGES.length > 0) {
      setImages(IMAGES.sort((a, b) => 0.5 - Math.random()));
    }
    return () =>
      document
        .getElementsByClassName("view-container")[0]
        .classList.remove("photos-view-container");
  }, [IMAGES]);
  return (
    <div className="photos-container">
      <div className="hero-image" />
      {/* <img
        src="http://alexkostich.com/images/photography/IMG_6502.JPG"
        alt="Alex Kostich using a camera"
        className="hero-image"
      /> */}
      <Gallery
        images={images}
        enableImageSelection={false}
        showLightboxThumbnails={true}
        // rowHeight={180}
        margin={10}
        className="gallery"
      />
    </div>
  );
};

export default Photos;
