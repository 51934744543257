import React from "react";
import { IoMdMail } from "react-icons/io";

export default function Footer(props) {
  return (
    <footer id="footer">
      <div className="app-footer">
        <a href="mailto:alexkostich11@gmail.com">
          <IoMdMail size={30} style={{ marginRight: "10px" }} />{" "}
        </a>
      </div>
    </footer>
  );
}
