import React from "react";
import { Link } from "react-router-dom";

const Contact = (props) => {
  return (
    <div className="App">
      <h2>GET IN TOUCH</h2>
      <div className="contact-container">
        <div className="image-container">
          <Link to={"/photos"}>
            <img
              alt={"Inverted Symmetry"}
              src={"http://alexkostich.com/images/photography/IMG_6504.JPG"}
            />
          </Link>
        </div>
        <div className="address-contact">
          <div className="detail-field">
            <h2>Location</h2>
            <p>
              Los Angeles <br /> California
            </p>
          </div>
          <div className="detail-field">
            <h2>Contact</h2>
            <p>
              <a href="mailto:alexkostich.com">alexkostich11@gmail.com</a>
              <br />
              <a href="https://www.linkedin.com/in/alexkostich/">LinkedIn</a>
              <br />
              <a href="tel:3104188050">310-418-8050</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
