import logo from "./logo.svg";
// import "./App.css";
import data from "./assets.json";
import AppRouter from "./routers/AppRouter.js";
import "./styles/styles.scss";
// import { Home } from "./Views/Home";
function App() {
  return <AppRouter />;
}

export default App;
