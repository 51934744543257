import React from "react";
import data from "../assets.json";

import { Link } from "react-router-dom";

const Home = (props) => {
  return (
    <div className="home">
      <h2>PARTICIPANT MEDIA (2016 - 2022)</h2>
      <div className="portfolio-container">
        {data.participant.map((item) => (
          <PortfolioItem
            title={item.title}
            cover={item.cover}
            id={item.id}
            key={item.id}
            videos={item.videos}
          />
        ))}
      </div>
      <br />
      <br />
      <br />

      <h2>SONY PICTURES RELEASING INTERNATIONAL (2003 - 2016)</h2>
      <div className="portfolio-container">
        {data.sony.map((item) => (
          <PortfolioItem
            title={item.title}
            cover={item.cover}
            id={item.id}
            key={item.id}
            videos={item.videos}
          />
        ))}
      </div>
    </div>
  );
};

export const PortfolioItem = (props) => {
  return (
    <Link
      to={{
        pathname: `/detail/${props.id}`,

        state: {
          title: props.title,
          cover: props.cover,
          videos: props.videos,
          images: props.images,
        },
      }}
    >
      <div
        className="portfolio-item"
        onClick={(e) => {
          // e.preventDefault();

          console.log("Portfolio Item Clicked");
        }}
      >
        <img src={props.cover} alt={props.title} />
      </div>
    </Link>
  );
};

export default Home;
