import React, { useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";

import data from "../assets.json";
import { PortfolioItem } from "./Home";

const s3url = "http://alexkostich.com/";

const getDetail = (id) => {
  const sonyItem = data.sony.find((item) => item.id === id);
  const participantItem = data.participant.find((item) => item.id === id);
  const socialImpactItem = data["social-impact"].find((item) => item.id === id);
  return sonyItem || participantItem || socialImpactItem;
};

export const Detail = (props) => {
  let params = useParams();
  const [ready, setReady] = useState(false);

  const detail = getDetail(params.id);

  //scroll to top of window on mount!
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const videos = useMemo(() => {
    const opts = {
      height: "390",
      width: "100%",
      playerVars: {
        rel: 0,
        showinfo: 0,
        autoplay: 0,
        modestbranding: 1,
      },
    };
    return detail.videos.map((video) => (
      <YouTube
        videoId={video}
        opts={opts}
        key={video}
        className="video"
        onError={(e) => {
          console.log("onError", e);
        }}
        onReady={(e) => setReady(true)}
      />
    ));
  }, [detail.videos]);
  console.log(videos);
  const coverImage = detail.cover.replace("/assets", s3url + "images");

  // check if social impact reels, if so, render each as it's own PortfolioItem!

  if (!videos) {
    return null;
  }

  if (detail.id === "16") {
    return (
      <div className="detail">
        <div className="top-details">
          <img src={coverImage} alt={detail.title} />
          <div className="title-credits">
            <h1>{detail.title?.toUpperCase()}</h1>
            <p>{detail.credits}</p>
          </div>
        </div>
        <h2>SOCIAL IMPACT PROJECTS</h2>
        <p>(click for more)</p>
        <div className="portfolio-container social-impact">
          {data["social-impact"].map((item) => (
            <PortfolioItem
              title={item.title}
              cover={item.cover}
              id={item.id}
              videos={item.videos}
            />
          ))}
        </div>
        {detail.videos?.length > 0 ? (
          <>
            <h2>CORPORATE IMPACT REEL {ready}</h2>
            {videos}
          </>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="detail">
        <div className="top-details">
          <img src={coverImage} alt={detail.title} />
          <div className="title-credits">
            <h1>{detail.title?.toUpperCase()}</h1>
            <p>{detail.credits}</p>
          </div>
        </div>

        {detail.images?.length > 0 ? (
          <>
            <h2>PRINT</h2>
            {detail.images.map((image) => {
              const imageStringArray = image.split("/");
              const imageString = imageStringArray.at(-1);
              return image.endsWith("_page_1.jpg") ? (
                <a href={image.replace("_page_1.jpg", ".pdf")}>
                  <img src={image} alt={imageString} />
                </a>
              ) : (
                <a href={image}>
                  <img src={image} alt={imageString} />
                </a>
              );
            })}
          </>
        ) : null}

        {detail.videos?.length > 0 ? (
          <>
            <h2>REELS</h2>
            {detail.id === "13" ? (
              <div className="vimeo-container">
                <Vimeo video="719399562" width={640} />
              </div>
            ) : null}
            {videos}
          </>
        ) : null}
      </div>
    );
  }
};
